export function flattenObject(input: Record<string, any>, parentKey: string = ''): Record<string, any> {
    const items: Record<string, any> = {}
    Object.keys(input).forEach((key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key
        const value = input[key]
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(items, flattenObject(value, newKey))
        }
        else {
            items[newKey] = value
        }
    })
    return items
}

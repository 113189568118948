import type { Tela } from '~/types/tela'

// TODO: docs!
export function useSchemaObject() {
    function flatSchema(schema: Tela.SchemaObject, options: { expandArrays: boolean } = { expandArrays: true }) {
        const properties: Tela.FlatObjectProperty[] = []
        const arrayProperties = listArrayProperties(schema)

        function extractProperties(schema: Tela.SchemaObject, prefix = '') {
            for (const key in schema) {
                if (schema[key].type === 'array' && options.expandArrays) {
                    if (schema[key].items.type !== 'object') {
                        properties.push({
                            key: prefix ? `${prefix}.${key}` : key,
                            value: schema[key],
                        })
                    }
                    else {
                        extractProperties(schema[key].items.properties, prefix ? `${prefix}.${key}` : key)
                    }
                }
                else if (schema[key].type !== 'object') {
                    properties.push({
                        key: prefix ? `${prefix}.${key}` : key,
                        value: schema[key],
                    })
                }
                else {
                    extractProperties(schema[key].properties, prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        extractProperties(schema)

        return {
            properties,
            arrayProperties,
        }
    }

    function listArrayProperties(schema: Tela.SchemaObject): string[] {
        const arrayProperties: string[] = []
        function searchArrayProperties(schema: Tela.SchemaObject, prefix = '') {
            for (const key in schema) {
                if (schema[key].type === 'array') {
                    arrayProperties.push(prefix ? `${prefix}.${key}` : key)
                    if (schema[key].items.type === 'object') {
                        searchArrayProperties(schema[key].items.properties, prefix ? `${prefix}.${key}` : key)
                    }
                }
                else if (schema[key].type === 'object') {
                    searchArrayProperties(schema[key].properties, prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        searchArrayProperties(schema)
        return arrayProperties
    }

    function flatOutput(data: Record<string, any>, options: { expandArrays: boolean } = { expandArrays: true }) {
        const properties: Tela.FlatObjectProperty[] = []
        function extractProperties(data: Record<string, any>, prefix = '') {
            for (const key in data) {
                // only consider an array as present if has items
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    // when expand array is true, we wan't to loop to read internal properties
                    if (data[key][0] && data[key][0].type === 'object' && options.expandArrays) {
                        extractProperties(data[key][0], prefix ? `${prefix}.${key}` : key)
                    }
                    else {
                        properties.push({
                            key: prefix ? `${prefix}.${key}` : key,
                            value: data[key],
                        })
                    }
                }
                else if (typeof data[key] !== 'object') {
                    properties.push({
                        key: prefix ? `${prefix}.${key}` : key,
                        value: data[key],
                    })
                }
                else {
                    extractProperties(data[key], prefix ? `${prefix}.${key}` : key)
                }
            }
        }

        extractProperties(data)

        return properties
    }

    function enlargeObject(obj: Record<string, any>): Record<string, any>[] {
        const output: Record<string, any>[] = []
        const baseEntry: Record<string, any> = {}
        const listEntries: { [key: string]: any[] } = {}

        Object.entries(obj).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                listEntries[key] = value
            }
            else {
                baseEntry[key] = value
            }
        })

        if (Object.keys(listEntries).length === 0) {
            return [baseEntry]
        }

        Object.entries(listEntries).forEach(([listKey, listItems]) => {
            listItems.forEach((item) => {
                if (typeof item === 'object' && item !== null) {
                    const flatItem = flattenObject(item, listKey)
                    const nestedResults = enlargeObject(flatItem)
                    nestedResults.forEach((result) => {
                        output.push({ ...baseEntry, ...result })
                    })
                }
                else {
                    output.push({ ...baseEntry, [listKey]: item })
                }
            })
        })

        return output
    }

    return {
        flatSchema,
        flatOutput,
        enlargeObject,
        listArrayProperties,
    }
}

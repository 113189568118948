export function useUIState() {
    return {
        dashboard: {
            isSwitchWorkspaceModalOpen: useState('is-switch-workspace-modal-open', () => false),
            isCreateProjectModalOpen: useState('is-create-project-modal-open', () => false),
            isCreateCanvasModalOpen: useState('is-create-canvas-modal-open', () => false),
        },
        sidebar: {
            isSidebarCollapsed: useState('sidebar-collapsed', () => false),
        },
        prompt: {
            isDeployModalOpen: useState('deploy-modal-open', () => false),
            isCraftAssistantModalOpen: useState('is-craft-assistant-modal-open', () => false),
        },
        promptApps: {
            isAppDetailsModalOpen: useState('is-app-config-modal-open', () => false),
            isAppSuccessModalOpen: useState('is-app-success-modal-open', () => false),
            isCsvDownloadModalOpen: useState('is-csv-download-modal-open', () => false),
            isShareAppModalOpen: useState('is-share-app-modal-open', () => false),
            isSettingsSheetOpen: useState('is-settings-sheet-open', () => false),
        },
        craft: {
            editorPanelSize: useState('editor-panel-size', () => 60),
            isPromptAssistantOpen: useState('is-prompt-assistant-open', () => false),
            isCreatingDraftVersion: useState('is-creating-draft-version', () => false),
            isShareModalOpen: useState('is-share-modal-open', () => false),
            isConnectModalOpen: useState('is-connect-modal-open', () => false),
        },
    }
}
